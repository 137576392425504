import React, {useEffect, useState} from 'react';
import {Button, CircularProgress, Tooltip} from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import {useNavigate} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import NewReleasesIcon from '@material-ui/icons/NewReleases';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    logo: {
        textAlign: "center",
        paddingTop: "25px"
    },
    loadingContainer: {
        paddingTop: "50px"
    },
    subHeadingContainer: {
        textAlign:"center"
    },
    subHeading: {
        paddingBottom: "10px"
    },
    skillRatingTitle: {
        color: theme.palette.primary.main,
        fontSize: "small"
    },
    skillItemSelected: {
        padding: '20px',
        margin: '20px',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        cursor: 'pointer',
        textTransform: "capitalize",
        textAlign: "center"
    },
    skillItem: {
        padding: '20px',
        margin: '20px',
        backgroundColor: theme.custom.skillItemBackground,
        cursor: 'pointer',
        textTransform: "capitalize",
        textAlign: "center"
    },
    saveButtonContainer: {
        padding: "50px"
    },
    avatar: {
        display: 'inline-flex',
        backgroundColor: theme.palette.primary.main
    },
    skillRatingTitleHero: {
        fontWeight: "bold",
        color:  theme.palette.primary.main
    },
    borderLeftAndRight: {
        borderLeft: "1px",
        borderRight: "1px"
    },
    emptyList: {
        textAlign: 'center',
        border: 'solid',
        borderRadius: 30,
        padding: 20,
        borderColor: '#909a9b',
        margin: 20,
        position: 'relative'
    },
    emptyListIcon: {
        fontSize: '3em',
    }
}))


export default function LearnStep({survey, response, saveResponse, getNextUrl}) {

    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [localResponse, setLocalResponse] = useState({})
    const [localSkills, setLocalSkills] = useState([])


    const navigate = useNavigate();

    useEffect(() => {
        setLocalResponse(response)
        let skills = []
        survey.segments.forEach(segment => {
            if (segment.isLearn) {
                skills = skills.concat(segment.skills)
            }
        })

        if (response.learn && Array.isArray(response.learn)) {
            skills.forEach((skill, index) => {
                response.learn.forEach(isLearnSkill => {
                    if (skill.title === isLearnSkill.title) {
                        skills[index].isLearn = true
                    }
                })
            })
        }

        setLocalSkills(skills)
        setIsLoading(false)
    },[survey, response]);

    const learnSkill = (index) => {
        let tempSkills = Object.assign([], localSkills)
        tempSkills[index].isLearn = !tempSkills[index].isLearn
        setLocalSkills(tempSkills)

        let tempResponse = Object.assign({}, localResponse)
        tempResponse.learn = tempSkills.filter(skill => skill.isLearn)
        setLocalResponse(tempResponse)
    }

    const buildSkillsList = (segments) => {
        let skillsJsx = []

        skillsJsx = localSkills.map((skill, index) => {
            return skillRatingJsx(skill, index)
        })

        if (skillsJsx.length) {
            return skillsJsx;
        }

        return (
            <div className={classes.emptyList}>
                <NewReleasesIcon className={classes.emptyListIcon}/>
                <Typography variant="subtitle1">You already know all of the listed skills. You can skip this step!</Typography>
            </div>
        )

    }

    const skillRatingJsx  = (skill, index) => {
        return (
            <Grid item lg={6} key={'index-' + index}>
                <Tooltip title={skill.description} aria-label="description" arrow placement="top">
                    <Paper onClick={() => learnSkill(index)} className={skill.isLearn ? classes.skillItemSelected : classes.skillItem} elevation={3} >{skill.title}</Paper>
                </Tooltip>
            </Grid>
        )
    }

    const save = async () => {
        setIsSaving(true)
        try {
            await saveResponse(localResponse);
            navigate(getNextUrl())
        } catch (e) {
            console.error(e)
            setIsSaving(false)
        }
    }

    return (
        <React.Fragment>
            {isLoading ?
                <Grid container className={classes.loadingContainer} direction="row" justify="center" alignItems="center">
                    <CircularProgress/>&nbsp;
                    <div>
                        <Typography variant="subtitle1">Loading your skills</Typography>
                    </div>
                </Grid>
                :
                <React.Fragment>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={12} className={classes.subHeadingContainer}>
                            <Typography className={classes.subHeading} variant="h4">What are you most interested in learning about?</Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={12}>
                            <Grid container direction="row" justify="center" alignItems="center">
                                {buildSkillsList()}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container className={classes.saveButtonContainer} direction="row" justify="center" alignItems="center">
                        {
                            isSaving ?
                                <CircularProgress/> :
                                <Button size="large" variant="contained" color="primary" onClick={save}>Next →</Button>
                        }
                    </Grid>
                </React.Fragment>
            }
        </React.Fragment>

    );
}
