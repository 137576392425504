import './App.css';
import {ThemeProvider} from '@material-ui/core/styles';
import {theme} from "./theme";
import {Route, Routes} from "react-router-dom";
import Welcome from "./components/Welcome";
import NotFound from "./components/NotFound";
import SurveyContainer from "./components/SurveyContainer";
import Complete from "./components/Done";
import React from "react";
import DashboardContainer from "./components/dashboard/DashboardContainer";


function App() {
    return (
      <ThemeProvider theme={theme}>
          <Routes>
              <Route path="/welcome/:tenantId/:surveyId" element={<Welcome />} />
              <Route path="/complete/:tenantId/:surveyId" element={<Complete />} />
              <Route path="/survey/:tenantId/:surveyId" element={<SurveyContainer />} />
              <Route path="/survey/:tenantId/:surveyId/:responseId" element={<SurveyContainer />} />
              <Route path="/survey/:tenantId/:surveyId/:responseId/:segmentIndex" element={<SurveyContainer />} />
              <Route path="/dashboard/:tenantId/:surveyId/:responseId" element={<DashboardContainer />} />
              <Route path="/*" element={<NotFound />} />
          </Routes>
      </ThemeProvider>
    );
}

export default App;
