import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import {useNavigate, useParams} from "react-router-dom";
import {withStyles} from "@material-ui/core";
import StepConnector from "@material-ui/core/StepConnector";

const Connector = withStyles((theme) => ({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: theme.palette.primary.main,
        },
    },
    completed: {
        '& $line': {
            borderColor: theme.palette.primary.main,
        },
    },
    line: {
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}))(StepConnector);

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        paddingBottom: "20px"
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },

}));

export default function SurveyStepper({steps, activeStep}) {
    const classes = useStyles();
    const {id} = useParams()
    const navigate = useNavigate();

    let stepsJsx = []
    if (steps) {
        stepsJsx = steps.map((segment => {
            return (
                <Step key={segment.title}>
                    <StepLabel>{segment.title}</StepLabel>
                </Step>
            )
        }))
    }

    const navigateToStep = (path) => {
        if (id) {
            navigate(path);
        }
    }

    return (
        <div className={classes.root}>
            <Stepper activeStep={parseInt(activeStep)} alternativeLabel connector={<Connector/>}>
                {stepsJsx}
            </Stepper>
        </div>
    );
}
