import React, {useEffect, useState} from 'react';
import {
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Collapse,
    Divider,
    FormControlLabel,
    FormGroup, Tooltip
} from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import {useNavigate} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import NewReleasesIcon from '@material-ui/icons/NewReleases';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    logo: {
        textAlign: "center",
        paddingTop: "25px"
    },
    loadingContainer: {
        paddingTop: "50px"
    },
    subHeadingContainer: {
        textAlign:"center"
    },
    subHeading: {
        paddingBottom: "10px"
    },
    skillRatingTitle: {
        color: theme.palette.primary.main,
        fontSize: "small"
    },
    skillItemHeaderDiv: {
        padding: 20,
        cursor: 'pointer'
    },
    skillItemBodyDiv: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        textTransform: "none"
    },
    skillItem: {
        margin: '20px',
        textTransform: "capitalize",
        textAlign: "center"
    },
    saveButtonContainer: {
        padding: "50px"
    },
    avatar: {
        display: 'inline-flex',
        backgroundColor: theme.palette.primary.main
    },
    skillRatingTitleHero: {
        fontWeight: "bold",
        color:  theme.palette.primary.main
    },
    borderLeftAndRight: {
        borderLeft: "1px",
        borderRight: "1px"
    },
    emptyList: {
        textAlign: 'center',
        border: 'solid',
        borderRadius: 30,
        padding: 20,
        borderColor: '#909a9b',
        margin: 20,
        position: 'relative'
    },
    emptyListIcon: {
        fontSize: '3em',
    }
}))


export default function CurrentlyUsing({segment, response, saveResponse, getNextUrl}) {

    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [localResponse, setLocalResponse] = useState({})
    const [localSkills, setLocalSkills] = useState([])


    const navigate = useNavigate();

    useEffect(() => {
        setLocalResponse(response)
        let skills = []
        segment.skills.forEach(skill => {
            skill.isUsing = false
            skill["haveEnoughTime"] = false
            skill["shouldBePartOfMyRole"] = false
            if (response.using) {
                response.using.forEach(skillInResponse => {
                    if (skillInResponse.title === skill.title) {
                        skill = skillInResponse
                    }
                })
            }
            skills.push(skill)
        })
        setLocalSkills(skills)
        setIsLoading(false)
    },[segment, response]);

    const isUsing = (index) => {
        let tempSkills = localSkills.slice()

        tempSkills[index].isUsing = !tempSkills[index].isUsing

        tempSkills[index].isSelected = tempSkills[index].isUsing;
        tempSkills[index].haveEnoughTime = tempSkills[index].isUsing
        tempSkills[index].shouldBePartOfMyRole = tempSkills[index].isUsing
        setLocalSkills(tempSkills)

    }

    const checkSkillBox = (event, index) => {
        let tempSkills = localSkills.slice()
        tempSkills[index][event.target.name] = event.target.checked
        setLocalSkills(tempSkills)
    }

    function buildSkillsList() {
        let skillsJsx = []

        skillsJsx = localSkills.map((skill, index) => {
            return skillRatingJsx(skill, index)
        })

        if (skillsJsx.length) {
            return skillsJsx;
        }

        return (
            <div className={classes.emptyList}>
                <NewReleasesIcon className={classes.emptyListIcon}/>
                <Typography variant="subtitle1">You can skip this step!</Typography>
            </div>
        )

    }

    const skillRatingJsx  = (skill, index) => {
            return (
                <Grid key={skill.title} item lg={6}>
                    <Tooltip title={skill.description} aria-label="description" arrow placement="top">
                        <Paper elevation={3} className={classes.skillItem}>
                            <div onClick={() => isUsing(index)}>
                                <div className={classes.skillItemHeaderDiv}>
                                    {skill.isUsing ? <Typography variant="h6">{skill.title} <Chip color="primary" label="Using" onClick={()=>{}}/></Typography> : (<Typography variant="h6">{skill.title} <Chip color="default" label="Not Using" onClick={()=>{}}/></Typography>)}
                                </div>
                            </div>
                            <Collapse in={skill.isUsing} collapsedHeight={0}>
                                <div className={classes.skillItemBodyDiv}>
                                    <Divider/>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox checked={skill["haveEnoughTime"]}
                                                               color="primary"
                                                               onChange={(event) => checkSkillBox(event, index)}
                                                               name="haveEnoughTime"/>}
                                            label="I have enough time to do it properly"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={skill["shouldBePartOfMyRole"]}
                                                               color="primary"
                                                               onChange={(event) => checkSkillBox(event, index)}
                                                               name="shouldBePartOfMyRole"/>}
                                            label={"This should be part of a " + response.role + " role"}
                                        />
                                    </FormGroup>
                                </div>
                            </Collapse>
                        </Paper>
                    </Tooltip>
                </Grid>
            )
    }

    const save = async () => {
        setIsSaving(true)
        let tempResponse = Object.assign({}, localResponse)
        tempResponse.using = localSkills.filter(skill => skill.isUsing)

        if (tempResponse.segments === undefined) {
            tempResponse.segments = {}
        }
        tempResponse.segments[segment.id] = tempResponse.using

        try {
            await saveResponse(tempResponse);
            navigate(getNextUrl())
        } catch (e) {
            console.error(e)
            setIsSaving(false)
        }
    }

    const skillListJsx = buildSkillsList()

    return (
        <React.Fragment>
            {isLoading ?
                <Grid container className={classes.loadingContainer} direction="row" justify="center" alignItems="center">
                    <CircularProgress/>
                </Grid>
                :
                <React.Fragment>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={12} className={classes.subHeadingContainer}>
                            <Typography className={classes.subHeading} variant="h4">{segment.title}</Typography>
                            <Typography className={classes.subHeading} variant="h5">{segment.description}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={12}>
                            <Grid container direction="row" justify="center" alignItems="center">
                                {skillListJsx}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container className={classes.saveButtonContainer} direction="row" justify="center" alignItems="center">
                        {
                            isSaving ?
                                <CircularProgress/> :
                                <Button size="large" variant="contained" color="primary" onClick={save}>Next →</Button>
                        }
                    </Grid>
                </React.Fragment>
            }
        </React.Fragment>

    );
}
