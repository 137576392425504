import React, {useEffect, useState} from 'react';
import {Button, CircularProgress, TextField} from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {useNavigate} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
    },
    subtitle: {
        paddingTop: "10px",
    }
}));

export default function ExperienceStep({survey, response, saveResponse, getNextUrl}) {

    const classes = useStyles();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [localResponse, setLocalResponse] = useState({})

    useEffect(() => {
        const tempResponse = Object.assign({}, response)
        tempResponse.experience = response.experience ? response.experience : 0
        tempResponse.name = response.name ? response.name : ''
        setLocalResponse(tempResponse)
        setIsLoading(false)
    },[response]);

    const createMenuItems = () => {
        const menuItems =  [];
        menuItems.push(<MenuItem key={1} value={1}>Sales Representatives of Services ☀️</MenuItem> )
        menuItems.push(<MenuItem key={2} value={2}>First-Line Supervisors of Non-Retail Sales Workers</MenuItem> )
        menuItems.push(<MenuItem key={3} value={3}>Real Estate Sales Agents</MenuItem> )
        menuItems.push(<MenuItem key={4} value={4}>Solar Sales Representatives and Assessors</MenuItem> )
        menuItems.push(<MenuItem key={5} value={5}>Brokerage Clerks</MenuItem> )
        menuItems.push(<MenuItem key={6} value={6}>... (2789 more)</MenuItem> )
        // for (let i  = 1; i < 25; i++) {
        //     menuItems.push(<MenuItem key={i} value={i}>{i}</MenuItem>)
        // }
        // menuItems.push(<MenuItem key={"25+"} value={"25+"}>25+</MenuItem>)
        return menuItems;
    }

    const setYearsExperience = (years) => {
        const tempResponse = Object.assign({}, localResponse)
        tempResponse.experience = years
        setLocalResponse(tempResponse)
    }

    const setName = (name) => {
        const tempResponse = Object.assign({}, localResponse)
        tempResponse.name = name
        setLocalResponse(tempResponse)
    }

    const setCurrentRole = (role) => {
        const tempResponse = Object.assign({}, localResponse)
        tempResponse.role = role
        setLocalResponse(tempResponse)
    }

    const save = async () => {
        setIsSaving(true)
        try {
            await saveResponse(localResponse);
            navigate(getNextUrl())
        } catch (e) {
            console.error(e)
            setIsSaving(false)
        }
    }

    return (
        <React.Fragment>
            <Paper elevation={3}  style={{padding: "40px"}}>
                {
                    isLoading ?
                        <Grid container className={classes.loadingContainer} direction="row" justify="center" alignItems="center">
                            <CircularProgress/>&nbsp;
                            <div>
                                <Typography variant="subtitle1">Loading</Typography>
                            </div>
                        </Grid>
                        :
                        <React.Fragment>
                            {/*<Grid container direction="row" justify="center" alignItems="center">*/}
                            {/*    <Grid item lg={10}>*/}
                            {/*        <Typography align="center" variant="h5">Please enter your name</Typography>*/}
                            {/*    </Grid>*/}
                            {/*</Grid>*/}

                            {/*<Grid container direction="row" justify="center" alignItems="center" style={{paddingBottom: "35px"}}>*/}
                            {/*    <Grid item lg={10}>*/}
                            {/*        <form noValidate autoComplete="off">*/}
                            {/*            <div style={{textAlign: "center"}}>*/}
                            {/*                <FormControl variant="outlined" className={classes.formControl}>*/}
                            {/*                    <TextField id="name" label="Name" value={localResponse.name} onChange={(event) => setName(event.target.value)}/>*/}
                            {/*                </FormControl>*/}
                            {/*            </div>*/}
                            {/*        </form>*/}
                            {/*    </Grid>*/}
                            {/*</Grid>*/}

                            {/*<Grid container direction="row" justify="center" alignItems="center">*/}
                            {/*    <Grid item lg={10}>*/}
                            {/*        <Typography align="center" variant="h5">What is your current role?</Typography>*/}
                            {/*    </Grid>*/}
                            {/*</Grid>*/}

                            {/*<Grid container direction="row" justify="center" alignItems="center" style={{paddingBottom: "35px"}}>*/}
                            {/*    <Grid item lg={10}>*/}
                            {/*        <form noValidate autoComplete="off">*/}
                            {/*            <div style={{textAlign: "center"}}>*/}
                            {/*                <FormControl variant="outlined" className={classes.formControl}>*/}
                            {/*                    <TextField id="currentRole" label="Role" value={localResponse.role} onChange={(event) => setCurrentRole(event.target.value)}/>*/}
                            {/*                </FormControl>*/}
                            {/*            </div>*/}
                            {/*        </form>*/}
                            {/*    </Grid>*/}
                            {/*</Grid>*/}

                            <Grid container direction="row" justify="center" alignItems="center">
                                <Grid item lg={10}>
                                    <Typography align="center" variant="h5">Which role are you interested in?</Typography>
                                </Grid>
                            </Grid>

                            <Grid container direction="row" justify="center" alignItems="center" style={{paddingTop: "35px"}}>
                                <Grid item lg={10}>
                                    <form noValidate autoComplete="off">
                                        <div style={{textAlign: "center"}}>
                                            <FormControl variant="outlined" className={classes.formControl}>
                                                <InputLabel id="years-experience-label">Role</InputLabel>
                                                <Select
                                                    labelId="years-experience-label"
                                                    id="years-experience-select"
                                                    value={localResponse.experience}
                                                    onChange={(event) => setYearsExperience(event.target.value)}
                                                    label="Role"
                                                >
                                                    {createMenuItems()}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </form>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                }

            </Paper>

            <Grid container direction="row" justify="center" alignItems="center" style={{paddingTop: "50px"}}>
                <Grid container className={classes.saveButtonContainer} direction="row" justify="center" alignItems="center">
                    {
                        isSaving ?
                            <CircularProgress/> :
                            <Button size="large" variant="contained" color="primary" onClick={save}>Next →</Button>
                    }
                </Grid>
            </Grid>
        </React.Fragment>

    );
}
