import axios from "axios";

const BASE_URL = 'https://h1j62qry4g.execute-api.us-east-1.amazonaws.com/dev'

export const generateResponse = async (tenantId, surveyId) => {
    const body = {
        tenantId,
        surveyId
    }

    return axios.post(BASE_URL + '/response', body)
        .then(data => {
            console.log(data)
            return data.data;
        })
        .catch(error => {
            console.error("Error in Axios call to 21skills backend", error)
        })
}

export const fetchResponse = async (tenantId, responseId) => {
    return axios.get(BASE_URL + '/response/' + responseId)
        .then(data => {
            console.log(data)
            return data.data;
        })
        .catch(error => {
            console.error("Error in Axios call to 21skills backend", error)
        })}

export const saveResponse = async (tenantId, response) => {
    console.log('Saving response', response)
    return axios.post(BASE_URL + '/response', response)
        .then(data => {
            console.log('response', data)
            return data.data;
        })
        .catch(error => {
            console.error("Error in Axios call to 21skills backend", error)
        })
}

export const fetchSurvey = async (tenantId, surveyId) => {
    return axios.get(BASE_URL + '/survey/' + surveyId)
        .then(data => {
            console.log('survey', data)
            return data.data;
        })
        .catch(error => {
            console.error("Error in Axios call to 21skills backend", error)
        })
}


