import React from "react";

export const JoyEnum = {
    HATE: 'hate',
    DISLIKE: 'dislike',
    LIKE: 'like',
    LOVE: 'love'
}

export  default function Emoji({joy}) {

    switch (joy) {
        case 1:
            return <span role="img" aria-label="Love it">{String.fromCodePoint(0x1F621)}</span>
        case JoyEnum.DISLIKE:
            return <span role="img" aria-label="Dislike it">{String.fromCodePoint(0x1F644)}</span>
        case 2:
            return <span role="img" aria-label="Like it">{String.fromCodePoint(0x1F642)}</span>
        case 3:
            return <span role="img" aria-label="Love it">{String.fromCodePoint(0x1F60D)}</span>
        default:
            return <span role="img" aria-label="Enjoy it">{String.fromCodePoint(0x1F600)}</span>
    }



}
