import React, {useEffect, useState} from 'react';
import {Button, CircularProgress, Tooltip} from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import {useNavigate, useParams} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    logo: {
        textAlign: "center",
        paddingTop: "25px"
    },
    skillItemSelected: {
        padding: '20px',
        margin: '20px',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        cursor: 'pointer',
        textTransform: "capitalize",
        textAlign: "center"
    },
    skillItem: {
        padding: '20px',
        margin: '20px',
        backgroundColor: theme.custom.skillItemBackground,
        cursor: 'pointer',
        textTransform: "capitalize",
        textAlign: "center"
    },
    loadingContainer: {
        paddingTop: "50px"
    },
    paperContainer: {
        padding: "40px"
    },
    subHeadingContainer: {
        textAlign:"center",
        padding: "25px"
    },
    saveButtonContainer: {
        padding: "50px"
    }
}))


export default function SkillsSegment({segment, response, saveResponse, getNextUrl}) {

    const classes = useStyles();
    const navigate = useNavigate();

    const [localSkills, setLocalSkills] = useState([])
    const [loading, setLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [localResponse, setLocalResponse] = useState({})


    useEffect(() => {
        //use the response to load the local skills with the correct state
        setLocalSkills(segment.skills)
        setIsSaving(false)
        setLocalResponse(response)
    },[segment, response]);

    const save = async () => {
        setIsSaving(true);
        const selectedSkills = localSkills.filter((skill) => {
            return skill.isSelected
        })
        const localResponse = Object.assign({}, response)
        if (localResponse.segments === undefined) {
            localResponse.segments = {}
        }
        localResponse.segments[segment.id] = selectedSkills
        await saveResponse(localResponse);
        navigate(getNextUrl());
    }

    const selectSkill = async (index) => {
        const updatedSkills = Object.assign([], localSkills);
        updatedSkills[index].isSelected = !updatedSkills[index].isSelected;
        setLocalSkills(updatedSkills)
    }

    const renderSkillsList = () => {
        const skillsList = [];
        localSkills.map((skill, index) => {
            skillsList.push(
                <Grid key={index} item lg={6}>
                    <Tooltip title={skill.description} aria-label="description" arrow placement="top">
                        <Paper onClick={() => selectSkill(index)} className={skill.isSelected ? classes.skillItemSelected : classes.skillItem} elevation={3} >{skill.title}</Paper>
                    </Tooltip>
                </Grid>
            )
        })
        return skillsList;
    }

    return (
        <React.Fragment>
            {loading ?
                <Grid container className={classes.loadingContainer} direction="row" justify="center" alignItems="center">
                    <CircularProgress/>&nbsp;
                    <div>
                        <Typography variant="subtitle1">Calculating essential skills</Typography>
                    </div>
                </Grid>
                :
                <React.Fragment>
                    <Paper elevation={3} className={classes.paperContainer}>
                        <Grid container direction="row" justify="center" alignItems="center">
                            <Grid item xs={6}>
                                <Typography variant="h5">{segment.title}</Typography>
                                <Typography variant="subtitle1">{segment.description}</Typography>
                            </Grid>
                            <Grid item xs={1}>
                            </Grid>
                            <Grid item xs={5}>
                                <img src={segment.imageUrl} height={"250px"} alt={"Computer man"} />
                            </Grid>
                        </Grid>
                    </Paper>
                    <div>
                        <Grid container direction="row" justify="center" alignItems="center">
                            <Grid item xs={10} className={classes.subHeadingContainer} >
                                <Typography variant="h6"><b>Which of these do you have experience with?</b></Typography>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justify="center" alignItems="center">
                            <Grid item xs={12}>
                                <Grid container direction="row" justify="center" alignItems="center">
                                    {renderSkillsList()}
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>

                    <Grid container className={classes.saveButtonContainer} direction="row" justify="center" alignItems="center">
                        {
                            isSaving ?
                                <CircularProgress/> :
                                <Button size="large" variant="contained" color="primary" onClick={save}>Next →</Button>
                        }
                    </Grid>
                </React.Fragment>

            }
        </React.Fragment>

    );
}

