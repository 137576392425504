import React, {useEffect, useState} from 'react';
import {Button, CircularProgress, Divider, Tooltip} from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import {useNavigate, useParams} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    logo: {
        textAlign: "center",
        paddingTop: "25px"
    },
    skillItem: {
        padding: '20px',
        margin: '20px',
        textTransform: "capitalize",
        textAlign: "center"
    },
    loadingContainer: {
        paddingTop: "50px"
    },
    subHeadingContainer: {
        textAlign:"center"
    },
    subHeading: {
        paddingBottom: "10px"
    },
    skillRatingAvatar: {
        margin: "auto",
        fontSize: "30px",
        filter: "grayscale(100%)"
    },
    skillRatingAvatarSelected: {
        margin: "auto",
        fontSize: "40px",
    },
    skillRatingAvatarContainer: {
        margin: "10px",
        cursor: 'pointer'
    },
    skillRatingTitle: {
        fontSize: "small"
    },
    skillRatingTitleSelect: {
        fontSize: "small",
        fontWeight: "bold"
    },
    saveButtonContainer: {
        padding: "50px"
    },
    skillRatingTitleHero: {
        fontWeight: "bold"
    },
    borderLeftAndRight: {
        borderLeft: "1px",
        borderRight: "1px"
    }
}))


export default function JoyStep({survey, response, saveResponse, getNextUrl}) {

    const classes = useStyles();

    const [isLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [segments, setSegments] = useState([])
    const [localResponse, setLocalResponse] = useState({})

    const navigate = useNavigate();

    useEffect(() => {
        setSegments(survey.segments)
        setLocalResponse(response)
    },[survey, response]);

    const rateSkill = (segmentId, index, rating) => {
        let tempResponse = Object.assign({}, localResponse)
        if (tempResponse.segments[segmentId] && tempResponse.segments[segmentId][index]) {
            tempResponse.segments[segmentId][index].joyRating = rating
        }
        setLocalResponse(tempResponse)
    }

    const buildSkillsList = (segments) => {
        let skills = [];
        segments.forEach(segment => {
            if (segment.isJoy) {
                if (localResponse.segments[segment.id] && Array.isArray(localResponse.segments[segment.id])) {
                    skills = skills.concat(localResponse.segments[segment.id].map((skill, index) => {
                        return skillRatingJsx(skill, index, segment.id)
                    }))
                }
            }
        })
        return skills;
    }

    const skillRatingJsx  = (skill, index, segmentId) => {
        if (!skill.joyRating) {
            skill.joyRating = 2
        }
        return (
            <Grid item lg={6} key={'segment-' + segmentId + '-index-' + index}>
                <Tooltip title={skill.description} aria-label="description" arrow placement="top">
                    <Paper className={classes.skillItem} elevation={3} >
                        <Grid container direction="row" justify="center" alignItems="center">
                            <Grid item xs={12} className={classes.subHeadingContainer}>
                                <Typography variant="h6">{skill.title}</Typography>
                                <Divider/>
                            </Grid>
                            <Grid onClick={() => rateSkill(segmentId, index, 1)}  item xs={3} className={classes.skillRatingAvatarContainer}>
                                <div className={skill.joyRating === 1 ? classes.skillRatingAvatarSelected : classes.skillRatingAvatar}>
                                    <span role="img" aria-label="frustrated">😩</span>
                                </div>
                                <Typography className={skill.joyRating === 1 ? classes.skillRatingTitleSelect : classes.skillRatingTitle} >Hate it</Typography>
                            </Grid>
                            <Grid onClick={() => rateSkill(segmentId, index, 2)} item xs={3} className={classes.skillRatingAvatarContainer}>
                                <div className={skill.joyRating === 2 ? classes.skillRatingAvatarSelected : classes.skillRatingAvatar}>
                                    <span role="img" aria-label="content">🙂</span>
                                </div>
                                <Typography className={skill.joyRating === 2 ? classes.skillRatingTitleSelect : classes.skillRatingTitle} >Don't Mind</Typography>
                            </Grid>
                            <Grid onClick={() => rateSkill(segmentId, index, 3)} item xs={3} className={classes.skillRatingAvatarContainer}>
                                <div className={skill.joyRating === 3 ? classes.skillRatingAvatarSelected : classes.skillRatingAvatar}>
                                    <span role="img" aria-label="love">😍</span>
                                </div>
                                <Typography className={skill.joyRating === 3 ? classes.skillRatingTitleSelect : classes.skillRatingTitle} >Love it</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Tooltip>
            </Grid>
        )
    }

    const save = async () => {
        setIsSaving(true)
        try {
            await saveResponse(localResponse);
            navigate(getNextUrl())
        } catch (e) {
            console.error(e)
            setIsSaving(false)
        }
    }

    return (
        <React.Fragment>
            {isLoading ?
                <Grid container className={classes.loadingContainer} direction="row" justify="center" alignItems="center">
                    <CircularProgress/>&nbsp;
                    <div>
                        <Typography variant="subtitle1">Loading your skills</Typography>
                    </div>
                </Grid>
                :
                <React.Fragment>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={12} className={classes.subHeadingContainer}>
                            <Typography className={classes.subHeading} variant="h4">How much joy does it bring you to use these skills?</Typography>

                            <Grid container direction="row" justify="center" alignItems="center">
                                <Grid item xs={1}>
                                    <div className={classes.skillRatingAvatarSelected}><span role="img" aria-label="frustrated">😩</span></div>
                                    <Typography className={classes.skillRatingTitleHero} variant="subtitle1">Hate it.</Typography>
                                </Grid>
                                <Grid item xs={1} className={classes.borderLeftAndRight}>
                                    <div className={classes.skillRatingAvatarSelected}><span role="img" aria-label="content">🙂</span></div>
                                    <Typography className={classes.skillRatingTitleHero} variant="subtitle1">Don't mind.</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <div className={classes.skillRatingAvatarSelected}><span role="img" aria-label="love">😍</span></div>
                                    <Typography className={classes.skillRatingTitleHero} variant="subtitle1">Love it.</Typography>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={12}>
                            <Grid container direction="row" justify="center" alignItems="center">
                                {buildSkillsList(segments)}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container className={classes.saveButtonContainer} direction="row" justify="center" alignItems="center">
                        {
                            isSaving ?
                                <CircularProgress/> :
                                <Button size="large" variant="contained" color="primary" onClick={save}>Next →</Button>
                        }
                    </Grid>
                </React.Fragment>
            }
        </React.Fragment>

    );
}
