import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useParams} from "react-router-dom";
import Overview from "./Overview";
import {fetchResponse, fetchSurvey, generateResponse} from "../../service/backendApi";
import FullScreenLoading from "../FullScreenLoading";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        minHeight: '100vh'
    },
    loadingContainer: {
        display: 'flex',
        height: '100%'
    },
    loadingDivStyle: {
        margin: 'auto'
    }
}));

export default function DashboardContainer({fetchProps, essentialSkills, isFetchingSkills}) {
    const classes = useStyles();
    const {tenantId, surveyId, responseId} = useParams()

    const [user, setUser] = useState(null);

    const [response, setResponse] = useState(null);
    const [survey, setSurvey] = useState(null);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        window.scrollTo(0,0)
        setIsLoading(true)
        if (!responseId) {
            // generateResponse(tenantId, surveyId).then(response => {
            //     navigate('/survey/' + tenantId + '/' + surveyId + '/' + response.id + '/' + 0)
            // })
        //    HANDLE NO RESPONSE ID
        } else {
            if (!response) {
                const fetchResponsePromise = fetchResponse(tenantId, responseId)
                const fetchSurveyPromise = fetchSurvey(tenantId, surveyId);
                Promise.all([fetchResponsePromise, fetchSurveyPromise]).then(([responseData, surveyData]) => {
                    setResponse(responseData)
                    setSurvey(surveyData)
                    setIsLoading(false)
                })
            } else {
                setIsLoading(false)
            }
        }
    }, [tenantId, surveyId, responseId, response, survey])

    return (
        <div className={classes.root}>
            <main className={classes.content}>
                {isLoading ? <FullScreenLoading/>: <Overview response={response} survey={survey} />}
            </main>
        </div>
    );
}
