import React, {useEffect, useState} from "react";
import {fetchResponse, fetchSurvey, generateResponse, saveResponse} from "../service/backendApi";
import {CircularProgress, Container} from "@material-ui/core";
import {useParams, useNavigate} from "react-router-dom";
import SkillsSegment from "./SkillsSegment";
import Grid from "@material-ui/core/Grid";
import Logo from "./Logo";
import SurveyStepper from "./SurveyStepper";
import JoyStep from "./JoyStep";
import RatingStep from "./RatingStep";
import LearnStep from "./LearnStep";
import ExperienceStep from "./ExperienceStep";
import AddToTeamStep from "./AddToTeamStep";
import CurrentlyUsing from "./CurrentlyUsing";
import FullScreenLoading from "./FullScreenLoading";

export default function SurveyContainer() {
    const {tenantId, surveyId, responseId, segmentIndex} = useParams();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [survey, setSurvey] = useState(undefined);
    const [steps, setSteps] = useState([]);
    const [response, setResponse] = useState({})

    useEffect(() => {
        window.scrollTo(0,0)
        setIsLoading(true)
        if (!responseId) {
            generateResponse(tenantId, surveyId).then(response => {
                navigate('/survey/' + tenantId + '/' + surveyId + '/' + response.id + '/' + 0)
            })
        } else {
            if (!survey || !response) {
                const fetchResponsePromise = fetchResponse(tenantId, responseId)
                const fetchSurveyPromise = fetchSurvey(tenantId, surveyId);
                Promise.all([fetchResponsePromise, fetchSurveyPromise]).then(([responseData, surveyData]) => {
                    setResponse(responseData)
                    setSurvey(surveyData)
                    calculateSteps(surveyData)
                    setIsLoading(false)

                    // console.log(JSON.stringify(surveyData, null, 2))
                })
            } else {
                setIsLoading(false)
            }
        }
    }, [tenantId, surveyId, responseId, segmentIndex, navigate, response, survey])

    const calculateSteps = (survey) => {
        const stepsLocal = []
        stepsLocal.push({
            type: 'experience',
            title: 'Experience'
        })
        survey.segments.forEach((segment) => {
            stepsLocal.push({
                type: 'skills',
                title: segment.title
            })
        })
        stepsLocal.push({
            type: 'joy',
            title: 'Joy'
        })
        stepsLocal.push({
            type: 'rating',
            title: 'Rating'
        })
        stepsLocal.push({
            type: 'learn',
            title: 'Learning'
        })
        // stepsLocal.push({
        //     type: 'addToTeam',
        //     title: 'Help wanted'
        // })
        setSteps(stepsLocal)
    }

    const saveResponseContainer = async (response) => {
        response = await saveResponse(tenantId, response)
        sendSegmentIdentify(response.id)
        setResponse(response)
    }

    const getNextUrl = () => {
        if (parseInt(segmentIndex) + 1 < steps.length) {
            return '/survey/' + tenantId + '/' + surveyId + '/' + responseId + '/' + (parseInt(segmentIndex) + 1)
        } else {
            return '/dashboard/' + tenantId + '/' + surveyId + '/' + responseId
        }
    }

    const sendSegmentIdentify = (id) => {
        window.analytics.identify(id, {});
    }

    const renderSegment = (steps, segmentIndex) => {
        const step = steps[segmentIndex];
        switch (step.type) {
            case 'experience':
                return <ExperienceStep survey={survey} response={response} saveResponse={saveResponseContainer} getNextUrl={getNextUrl} />
            case 'rating':
                return <RatingStep survey={survey} response={response} saveResponse={saveResponseContainer} getNextUrl={getNextUrl} />
            case 'joy':
                return <JoyStep survey={survey} response={response} saveResponse={saveResponseContainer} getNextUrl={getNextUrl} />
            case 'learn':
                return <LearnStep survey={survey} response={response} saveResponse={saveResponseContainer} getNextUrl={getNextUrl} />
            case 'addToTeam':
                return <AddToTeamStep survey={survey} response={response} saveResponse={saveResponseContainer} getNextUrl={getNextUrl} />
            default:
                // return <CurrentlyUsing segment={survey.segments[segmentIndex - 1]} response={response} saveResponse={saveResponseContainer} getNextUrl={getNextUrl}/>
                return <SkillsSegment segment={survey.segments[segmentIndex - 1]} response={response} saveResponse={saveResponseContainer} getNextUrl={getNextUrl}/>
        }
    }

    return (
        <Container>
            {isLoading ? <FullScreenLoading/> :
                <React.Fragment>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Logo/>
                    </Grid>
                    <SurveyStepper steps={steps} activeStep={segmentIndex}/>
                    {renderSegment(steps, segmentIndex)}
                </React.Fragment>
            }
        </Container>
    )
}
